import React from "react"

import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"

import BookingForm from "../../../../components/dynamic/BookingForm"

function TestTour(props) {
  return (
    <>
      <SEO title="Test Tour - awesomeNZ" />
      <Layout
        layoutType="typography TourPage"
        slideshow={
          <ul class="slideshow-slides">
            <li class="slideshow-slide first">
              <img
                src={require("../../../../images/slideshow/hobbit-hole.jpg")}
                alt=""
                class="first last image"
              />
            </li>
          </ul>
        }
        hasSliderHandles={false}
        hasSliderBlurLarge={true}
        content={
          <>
            {/*<BookingForm tourCode="G76L"/>*/}
            {/*<BookingForm tourCode="GS1K"/>*/}
            {/*<BookingForm tourCode="CRW"/>*/}
            <BookingForm tourCode="G76L" />

            <div id="row" class="fourteen clearfix">
              <div class="title-div clearfix">
                <h1>Test Tour Page</h1>
                <div class="highlights">
                  <ul>
                    <li>Test</li>
                  </ul>
                </div>
              </div>
              <div id="Overview" class="clearfix">
                <div id="Content" class="clearfix">
                  <div id="ContentTab">
                    {/* Content from awesomeNZ tour page goes here */}
                    <p>Test.</p>
                    {/* ... */}
                    <div class="clear"></div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      />
    </>
  )
}

export default TestTour
